import BaseModel from "./base";
import { IDictionary } from "types";

class CreateDocumentModel extends BaseModel {
  name: string;
  type: string;
  exclusivityStart: string;
  exclusivityEnd: string;
  price: string;
  address: string;
  zip: string;
  city: string;
  phone: string;
  budget: string;
  notes: string;
  receipts: string[];
  clients: string[];

  constructor(document: IDictionary<string | string[]>, msg: IDictionary<string>) {
    super(msg);

    this.name = typeof document.name === 'string' ? document.name : "";
    this.type = typeof document.type === 'string' ? document.type : "";
    this.exclusivityStart = typeof document.exclusivityStart === 'string' ? document.exclusivityStart : "";
    this.exclusivityEnd = typeof document.exclusivityEnd === 'string' ? document.exclusivityEnd : "";
    this.price = typeof document.price === 'string' ? document.price : "";
    this.address = typeof document.address === 'string' ? document.address : "";
    this.zip = typeof document.zip === 'string' ? document.zip : "";
    this.city = typeof document.city === 'string' ? document.city : "";
    this.phone = typeof document.phone === 'string' ? document.phone : "";
    this.budget = typeof document.budget === 'string' ? document.budget : "";
    this.notes = typeof document.notes === 'string' ? document.notes : "";
    this.receipts = Array.isArray(document.receipts) ? document.receipts : [];
    this.clients = Array.isArray(document.clients) ? document.clients : [];

    this.validationMsgs = msg;
    this.validationRules = {
      name: {
        presence: { allowEmpty: false, message: msg.name },
      },
      type: {
        presence: { allowEmpty: false, message: msg.type },
      },
      receipts: {
        type: "array",
        length: {
          minimum: 1,
          message: msg.receipts,
        },
      },
      clients: {
        type: "array",
        length: {
          minimum: 1,
          message: msg.clients,
        },
      },
    };
  }
}

export default CreateDocumentModel;
