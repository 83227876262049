import { Task, TaskStatus } from "types";
import BaseService from "./baseService";
import { LocalStorageKeys } from "./localStorage";
import ServiceProvider from "./provider";

const now = new Date();
const futureDate = new Date();
futureDate.setHours(now.getHours() + 1);

let tasks = [
  {
    id: "1",
    text: "Make something special",
    status: TaskStatus.Completed,
    createdAt: now,
  },
  {
    id: "2",
    text: "Call customer",
    status: TaskStatus.InProgress,
    createdAt: futureDate,
  },
];

export default class TasksService extends BaseService {
  constructor() {
    super("tasks");
  }

  async setStatus(taskId: string, status: TaskStatus): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const task = tasks.find((t) => t.id === taskId);
        if (task) {
          task.status = status;
        }
        resolve();
      }, 1000);
    });
  }

  async getAssetAll(assetId: string): Promise<{ data: Task[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Tasks}-${assetId}`) ?? [];
        resolve({
          data,
        });
      }, 1000);
    });
  }

  async addToAsset(text: string, status: TaskStatus, assetId: string) {
    return new Promise((resolve) => {
      const newTask = {
        id: Math.floor(Math.random() * 100000),
        text,
        status,
        createdAt: new Date(),
      }
      setTimeout(
        () => {
          ServiceProvider.LocalStorage.setItem(`${LocalStorageKeys.Tasks}-${assetId}`, [
            ...(ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Tasks}-${assetId}`) ?? []),
            newTask
          ])
          resolve(newTask)
        },
        1000
      );
    })
  }

  async getTasksAll(buyerId: string): Promise<Task[]> {
    return new Promise((resolve) => {
      setTimeout(() => resolve(tasks), 1000);
    });
  }
}
