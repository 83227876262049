import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "flowbite-react";
import { DocumentStatus } from "types";
import TypeButton from "components/TypeButton";
import { useNavigate } from "react-router-dom";
import { Pages } from "../../enums/pages";
import { BsExclamationCircle } from "react-icons/bs";
import { FaRegClock } from "react-icons/fa";
import { FaRegCheckCircle } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";


interface Props {
  selectedDocumentStatus: DocumentStatus,
  setSelectedDocumentStatus: Dispatch<SetStateAction<DocumentStatus>>,
  setIsHistoryLogOpen: Dispatch<SetStateAction<boolean>>,
}

function DocumentStatusesSection({ selectedDocumentStatus, setSelectedDocumentStatus, setIsHistoryLogOpen }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <div className="font-semibold text-lg">
          {t("pages.Documents.chooseStatus")}
        </div>
        <div className="flex gap-4">
          <Button color="cta" onClick={() => navigate(`${Pages.Signatures}`)}>
            {t("pages.Documents.signatures")}
          </Button>
          <Button color="cta" onClick={() => navigate(`${Pages.CreateDocument}`)}>
            {t("pages.Documents.createDocument")}
          </Button>
          <Button color="cta" onClick={() => setIsHistoryLogOpen(true)}>
            {t("pages.Documents.historyLog")}
          </Button>
        </div>
      </div>
      <div className="flex gap-8 w-full items-center mb-4">
        {[
          {
            title: "components.DocumentStatusesSection.NeedAction",
            category: DocumentStatus.NeedAction,
            icon: <BsExclamationCircle
              className="w-5 h-5"
            />,
          },
          {
            title: "components.DocumentStatusesSection.WaitingForSign",
            category: DocumentStatus.WaitingForSign,
            icon: <FaRegClock
              className="w-5 h-5"
            />,
          },
          {
            title: "components.DocumentStatusesSection.Signed",
            category: DocumentStatus.Signed,
            icon: <FaRegCheckCircle
              className="w-5 h-5"
            />,
          },
          {
            title: "components.DocumentStatusesSection.Rejected",
            category: DocumentStatus.Rejected,
            icon: <RiCloseCircleLine
              className="w-5 h-5"
            />,
          },
        ].map((btn) => {
          return (
            <TypeButton
              key={btn.category}
              label={t(btn.title)}
              icon={btn.icon}
              isSelected={btn.category === selectedDocumentStatus}
              onClick={() => setSelectedDocumentStatus(btn.category)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default DocumentStatusesSection;
