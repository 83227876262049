import React, { useState, createContext, useContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pages } from "../enums/pages";
import { makeProfile, setProfile } from "../pages/Login/reducer";
import { AppDispatch } from "../store";
import ServiceProvider from "services/provider";
import { LocalStorageKeys } from "services/localStorage";

export interface UserProfile {
  Id: string;
  brokerDetails: {
    address: {
      city: string;
      country: string;
    };
    israelIdNumber: string;
    licenceNumber: string;
  };
  emailAddress: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: string;
  address: {
    city: string;
    country: string;
  };
  avatarUrl: string;
}

interface AuthContextValue {
  user: UserProfile | null;
  login: (data: UserProfile) => void;
  logout: () => void;
  locationToNavigate: string;
  setLocationToNavigate: React.Dispatch<React.SetStateAction<string>>;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const user = useSelector(makeProfile);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [locationToNavigate, setLocationToNavigate] = useState<string>(
    Pages.Assets
  );

  const value = useMemo(() => {
    const login = async (data: UserProfile) => {
      dispatch(setProfile(data));
      navigate(Pages.Assets);
    };

    const logout = () => {
      dispatch(setProfile(null));
      ServiceProvider.LocalStorage.removeItem(LocalStorageKeys.Token);
      navigate(Pages.Login, { replace: true });
    };

    return {
      user,
      login,
      logout,
      locationToNavigate,
      setLocationToNavigate,
    };
  }, [user, locationToNavigate, dispatch, navigate]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
