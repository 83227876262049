import { Button } from "flowbite-react";
import React from "react";
import { Iconly } from "react-iconly";
import "./IconButton.css";

interface IconButtonProps {
  icon: string | React.ReactElement;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const IconButton: React.FC<IconButtonProps> = ({ icon, onClick }) => {
  return (
    <Button
      className="icon-button"
      color="ghost"
      theme={{
        base: "p-0",
        size: {
          md: "p-2 text-sm",
          lg: "p-2 text-base",
          xl: "p-2 text-base",
        },
      }}
      onClick={onClick}
    >
      <div>{typeof icon === "string" ? <Iconly name={icon} /> : icon}</div>
    </Button>
  );
};

export default IconButton;
