import { INote } from "types";
import BaseService from "./baseService";
import { LocalStorageKeys } from "./localStorage";
import ServiceProvider from "./provider";

export default class NotesService extends BaseService {
  constructor() {
    super("notes");
  }

  async getPropertyAll(assetId: string): Promise<{ data: INote[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Notes}-${assetId}`) ?? [];
        resolve({
          data,
        });
      }, 1000);
    });
  }

  async addToProperty(text: string, assetId: string) {
    return new Promise((resolve) => {
      const newNote = {
        id: Math.floor(Math.random() * 100000),
        note: text,
        createdAt: new Date(),
      }
      setTimeout(
        () => {
          ServiceProvider.LocalStorage.setItem(`${LocalStorageKeys.Notes}-${assetId}`, [
            ...(ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Notes}-${assetId}`) ?? []),
            newNote
          ])
          resolve(newNote)
        },
        1000
      );
    })
  }

  async deleteById(id: string, parentId: string) {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          ServiceProvider.LocalStorage.setItem(`${LocalStorageKeys.Notes}-${parentId}`, 
            ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Notes}-${parentId}`)
              .filter((item: INote) => item.id !== id) ?? []
          )
          resolve(id)
        },
        1000
      );
    })
  }

  async updateById(noteId: string, newText: string, parentId: string) {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          ServiceProvider.LocalStorage.setItem(`${LocalStorageKeys.Notes}-${parentId}`, 
            ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Notes}-${parentId}`)
              .map((item: INote) => item.id === noteId ? { ...item, note: newText } : item) ?? []
          )
          resolve(noteId)
        },
        1000
      );
    })
  }

  async getNotesAll(buyerId: string): Promise<INote[]> {
    return new Promise((resolve) => {
      const now = new Date();
      const futureDate = new Date();
      futureDate.setHours(now.getHours() + 1);
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce tristique quam justo, eget iaculis lorem imperdiet vel. Nam dui felis, dignissim id turpis et, iaculis vestibulum quam. Pellentesque vestibulum facilisis libero, vitae auctor nunc ornare id. Praesent congue lorem id massa eleifend sagittis. Quisque eu tristique purus. Phasellus metus ligula, fringilla vel est ut, ullamcorper iaculis diam. Cras vel ex augue. In hac habitasse platea dictumst. Mauris volutpat eu tellus vitae pellentesque.",
              createdAt: now,
            },
            {
              id: "2",
              note: "Second note",
              createdAt: futureDate,
            },
          ]),
        1000
      );
    });
  }
}
