import React, { createContext, useContext, useState, ReactNode } from "react";
import { Toast } from "flowbite-react";
import { HiCheck, HiExclamation, HiX } from "react-icons/hi";
import clsx from "clsx";

type ToastType = "success" | "error" | "warning" | "info";

export interface ToastMessage {
  message: string;
  type: ToastType;
}

interface ToastContextProps {
  showToast: (msgs: ToastMessage[]) => void;
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<ToastMessage[]>([]);

  const showToast = (msgs: ToastMessage[]) => {
    setToasts(msgs);
    const messages = msgs.map((m) => m.message);
    setTimeout(() => {
      setToasts((p) => p.filter((t) => !messages.includes(t.message)));
    }, 3000);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <div className="fixed top-2 left-2 space-y-2">
        {toasts.map((toast) => (
          <Toast key={toast.message} color={toast.type}>
            <div
              className={clsx(
                "inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg",
                {
                  "bg-green-100 text-green-500": toast.type === "success",
                  "bg-red-100 text-red-500": toast.type === "error",
                  "bg-orange-100 text-orange-500": toast.type === "warning",
                  "bg-cyan-100 text-cyan-500": toast.type === "info",
                }
              )}
            >
              {toast.type === "success" && <HiCheck className="h-5 w-5" />}
              {(toast.type === "warning" || toast.type === "info") && (
                <HiExclamation className="h-5 w-5" />
              )}
              {toast.type === "error" && <HiX className="h-5 w-5" />}
            </div>
            <div className="ml-3 text-sm font-normal">{toast.message}</div>
            <Toast.Toggle />
          </Toast>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
