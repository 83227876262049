import { ISignature } from "types";
import BaseService from "./baseService";
import { LocalStorageKeys } from "./localStorage";
import ServiceProvider from "./provider";

export default class SignaturesService extends BaseService {
  constructor() {
    super("signatures");
  }

  async getAll(): Promise<{ count: number; results: ISignature[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Signatures) ?? [];
        resolve({
          count: results.length,
          results,
        });
      }, 1000);
    });
  }
}
