import { Spinner } from "flowbite-react";

const THEME = {
  base: "inline animate-spin text-gray-200",
  color: {
    warning: "fill-highlighted",
  },
  size: {
    m: "h-5 w-5",
    xl: "h-10 w-10",
  },
};

interface IProps {
  size?: 'xl' | 'm'
}

export function Loader({size = 'xl'}: IProps) {
  return <Spinner color="warning" theme={THEME} size={size} />;
}
