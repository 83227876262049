import { ReactComponent as MinimizeIcon } from "assets/images/minimize.svg";
import IconButton from 'components/IconButton/IconButton';
import { Dispatch, SetStateAction } from "react";

interface ICustomSmallCalendarLabel {
  label: string,
  setSmallCalendarVisible: Dispatch<SetStateAction<boolean>>,
}

const CustomSmallCalendarLabel = ({ label, setSmallCalendarVisible }: ICustomSmallCalendarLabel) => {
  const handleMinimizeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setSmallCalendarVisible(false)
  }

  return (
    <div className='flex items-center justify-between'>
      <IconButton
        icon={<MinimizeIcon />}
        onClick={handleMinimizeClick} 
      />
      <p className='grow'>{label}</p>
    </div>
  )
}

export default CustomSmallCalendarLabel;