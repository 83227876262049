import { IHistoryItem } from "../pages/Documents/reducer";
import { IDocument } from "types";
import BaseService from "./baseService";
import { LocalStorageKeys } from "./localStorage";
import ServiceProvider from "./provider";

export default class DocumentsService extends BaseService {
  constructor() {
    super("documents");
  }

  async getDocumentsByCustomer(customerId: string): Promise<IDocument[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Documents)
          .filter((document: IDocument) => document.clients.includes(customerId)) ?? [];
        resolve(results);
      }, 1000);
    });
  }

  async getAll(): Promise<{ count: number; results: IDocument[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Documents) ?? [];
        resolve({
          count: results.length,
          results,
        });
      }, 1000);
    });
  }

  async getHistoryLog(): Promise<IHistoryItem[]> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.DocumentsHistoryLog) ?? [];
        resolve(results);
      }, 1000);
    });
  }
}
