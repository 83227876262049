import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentsDataSourceAsync,
  makeDocumentsDataSource,
} from "./reducer";
import { AppDispatch } from "../../store";
import DocumentsTable from "./DocumentsTable";
import { DocumentStatus } from "types";
import { useLocation } from "react-router-dom";
import DocumentStatusesSection from "./DocumentStatusesSection";
import HistoryLogSidebar from "./HistoryLogSidebar";

function Documents() {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [selectedDocumentStatus, setSelectedDocumentStatus] = useState<DocumentStatus>(
    DocumentStatus.NeedAction
  );
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | null>(null);
  const [isHistoryLogOpen, setIsHistoryLogOpen] = useState(false);

  const documentsData = useSelector(makeDocumentsDataSource);

  const refetchDocumentsData = useCallback(() => {
    dispatch(getDocumentsDataSourceAsync());
  }, [dispatch])

  useEffect(() => {
    refetchDocumentsData();

    if (location.state) {
      if (location.state.selectedDocumentId) {
        setSelectedDocumentId(location.state.selectedDocumentId);
      }
      if (location.state.selectedDocumentStatus) {
        setSelectedDocumentStatus(+location.state.selectedDocumentStatus);
      }
    }
  }, [selectedDocumentStatus, dispatch, location.state, refetchDocumentsData]);

  const needActionDocuments = documentsData.data
    .filter((document) => document.status === DocumentStatus.NeedAction);
  const waitingForSignDocuments = documentsData.data
    .filter((document) => document.status === DocumentStatus.WaitingForSign);
  const signedDocuments = documentsData.data
    .filter((document) => document.status === DocumentStatus.Signed);
  const rejectedDocuments = documentsData.data
    .filter((document) => document.status === DocumentStatus.Rejected);

  const getDocumentsForTable = () => {
    switch (selectedDocumentStatus) {
    case DocumentStatus.NeedAction:
      return needActionDocuments;
    case DocumentStatus.WaitingForSign:
      return waitingForSignDocuments;
    case DocumentStatus.Signed:
      return signedDocuments;
    case DocumentStatus.Rejected:
      return rejectedDocuments;
    default:
      return [];
    }
  };

  return (
    <div className="flex gap-8 property-layout">
      <div className="flex flex-col gap-6 flex-1 px-4">
        <DocumentStatusesSection
          selectedDocumentStatus={selectedDocumentStatus} 
          setSelectedDocumentStatus={setSelectedDocumentStatus}
          setIsHistoryLogOpen={setIsHistoryLogOpen}
        />
        <DocumentsTable 
          dataSource={getDocumentsForTable()} 
          selectedDocumentId={selectedDocumentId}
          setSelectedDocumentId={setSelectedDocumentId}
          refetchDocumentsData={refetchDocumentsData}
        />
        <HistoryLogSidebar
          isOpen={isHistoryLogOpen}
          onClose={() => setIsHistoryLogOpen(false)}
        />
      </div>
    </div>
  );
}

export default Documents;
