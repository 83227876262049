import { useTranslation } from "react-i18next";

import SignatureItem from "components/SignatureItem";
import LabeledInput from "components/LabeledInput/LabeledInput";
import { TextInput } from "flowbite-react";
import { useCallback, useEffect, useState } from "react";
import SignatureCanvas from "components/SignatureCanvas";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { getSignaturesDataSourceAsync, makeSignaturesDataSource } from "./reducer";
import ServiceProvider from "services/provider";
import { LocalStorageKeys } from "services/localStorage";
import { ISignature } from "types";
import ClickableIcon from "components/ClickableIcon/ClickableIcon";

function Signatures() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const signaturesData = useSelector(makeSignaturesDataSource);

  const [newSignatureName, setNewSignatureName] = useState('');
  const [newSignatureImage, setNewSignatureImage] = useState<string | null>(null);

  const getAllSignatures = useCallback(() => {
    dispatch(getSignaturesDataSourceAsync());
  }, [dispatch])

  useEffect(() => {
    getAllSignatures();
  }, [getAllSignatures]);

  const handleSaveSignature = () => {
    ServiceProvider.LocalStorage.setItem(LocalStorageKeys.Signatures, [
      ...(ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Signatures) ?? []),
      // Temporary
      {
        id: `${Math.floor(Math.random() * 10000000)}`,
        title: newSignatureName,
        image: newSignatureImage,
        isMain: !ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Signatures)?.length
      }
    ])
    setNewSignatureName('');
    getAllSignatures();
  }

  const changeMainSignature = (id: string) => {
    ServiceProvider.LocalStorage.setItem(
      LocalStorageKeys.Signatures,
      ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Signatures).map((signature: ISignature) => {
        if (signature.id === id) {
          return {
            ...signature,
            isMain: true,
          }
        }
        return {
          ...signature,
          isMain: false,
        }
      })
    )
    getAllSignatures();
  }

  const deleteSignature = (id: string) => {
    ServiceProvider.LocalStorage.setItem(
      LocalStorageKeys.Signatures,
      ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Signatures)
        .filter((signature: ISignature) => signature.id !== id)
    )
    getAllSignatures();
  }

  return (
    <div className="flex gap-8 property-layout">
      <div className="grid grid-cols-2 gap-6 px-4 w-full">
        <div className="flex flex-col">
          <div className="flex flex-wrap items-start gap-4">
            {signaturesData.data.map((signature) => (
              <SignatureItem 
                key={signature.id}
                image={signature.image} 
                title={signature.title} 
                isMain={signature.isMain} 
                handleClick={() => changeMainSignature(signature.id)}
                deleteSignature={<ClickableIcon
                  icon="Delete"
                  onClick={() => deleteSignature(signature.id)}
                  title={t("components.QuickActions.delete")}
                />}
              />
            ))}
          </div>
        </div>
        <div className='flex flex-col gap-4'>
          <p className="font-semibold text-lg">
            {t("pages.Signatures.newSignature")}
          </p>
          <LabeledInput
            title={t("pages.Signatures.form.name.label")}
            id="name"
          >
            <TextInput
              placeholder={t("pages.Signatures.form.name.placeholder")}
              id="name"
              value={newSignatureName}
              onChange={(e) =>
                setNewSignatureName(e.target.value)
              }
            />
          </LabeledInput>
          <SignatureCanvas 
            onSaveProvisional={setNewSignatureImage} 
            onClear={() => setNewSignatureImage(null)}
            isSubmitDisabled={!newSignatureName || !newSignatureImage}
            handleSaveSignature={handleSaveSignature}
          />
        </div>
      </div>
    </div>
  );
}

export default Signatures;