import { MEDIATION_ASSETS_MOCK, PRIVATE_ASSETS_MOCK } from "../consts";
import {
  IAsset,
  IHousingPreferences,
} from "types";
import { LocalStorageKeys } from "services/localStorage";
import ServiceProvider from "services/provider";

const filterAssetsByHousingPreferences = (asset: IAsset, preferences: IHousingPreferences) => {
  return (
    (asset.floor >= preferences.floorFrom && asset.floor <= preferences.floorTo)
    && (asset.price >= preferences.priceFrom && asset.price <= preferences.priceTo)
    && (asset.numberOfRooms >= preferences.roomsFrom && asset.numberOfRooms <= preferences.roomsTo))
}

export const getBuyersMatches = (preferences: IHousingPreferences) => {
  const myAssets = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.MyAssets) ?? [];
  const officeAssets = ServiceProvider.LocalStorage.getItem(LocalStorageKeys.OfficeAssets) ?? [];

  return {
    board: PRIVATE_ASSETS_MOCK.concat(MEDIATION_ASSETS_MOCK).filter((asset) => {
      return filterAssetsByHousingPreferences(asset, preferences)
    }),
    private: myAssets.concat(officeAssets).filter((asset: IAsset) => {
      return filterAssetsByHousingPreferences(asset, preferences)
    }),
    mls: [],
  }
}