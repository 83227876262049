import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "services/provider";
import { getError } from "utils/errors";
import { UserProfile } from "hooks/useAuth";
import LoginModel from "../../models/login";
import { LocalStorageKeys } from "services/localStorage";

export interface LoginStore {
  profile: UserProfile | null;
  error: string | null;
  loading: boolean;
  loadingProfile: boolean;
}

const initialState: LoginStore = {
  profile: null,
  error: null,
  loading: false,
  loadingProfile: false,
};

export const processLoginAsync = createAsyncThunk(
  "login/login",
  async (model: LoginModel) => {
    const resp = await ServiceProvider.Auth.login(model.email, model.password);
    const loginResponse = get(resp, "data.login", { token: null });
    if (loginResponse.token) {
      ServiceProvider.LocalStorage.setItem(
        LocalStorageKeys.Token,
        loginResponse.token
      );
    }
    return loginResponse;
  }
);

export const getProfileAsync = createAsyncThunk("login/profile", async () => {
  return await ServiceProvider.Auth.getProfile();
});

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.profile = get(action, "payload", null);
    },
    setLoginError: (state, action) => {
      state.error = get(action, "payload", null);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(processLoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(processLoginAsync.fulfilled, (state, action) => {
        state.profile = get(action, "payload", null);
        state.loading = false;
      })
      .addCase(processLoginAsync.rejected, (state, action) => {
        state.profile = null;
        state.loading = false;
        state.error = getError(action);
      })
      .addCase(getProfileAsync.pending, (state) => {
        state.loadingProfile = true;
      })
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.profile = get(action, "payload", null);
        state.loadingProfile = false;
      })
      .addCase(getProfileAsync.rejected, (state) => {
        state.profile = null;
        state.loadingProfile = false;
      });
  },
});

export const { setProfile, setLoginError } = loginSlice.actions;

export const makeProfile = (state: { login: LoginStore }) =>
  state.login.profile;
export const makeLoginError = (state: { login: LoginStore }) =>
  state.login.error;
export const makeLoginLoading = (state: { login: LoginStore }) =>
  state.login.loading;
export const makeProfileLoading = (state: { login: LoginStore }) =>
  state.login.loadingProfile;

export default loginSlice.reducer;
