import { Iconly } from "react-iconly";

const ClickableIcon = ({
  icon,
  onClick,
  title,
}: {
  icon: string | React.ReactElement;
  onClick: () => void;
  title?: string;
}) => {
  return (
    <div
      onClick={onClick}
      className="cursor-pointer inline-block"
      title={title}
      role="button"
      tabIndex={0}
    >
      <div>{typeof icon === "string" ? <Iconly name={icon} /> : icon}</div>
    </div>
  );
};

export default ClickableIcon;
