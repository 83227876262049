import { COMMERCIAL_ASSETS_MOCK, MEDIATION_ASSETS_MOCK, PRIVATE_ASSETS_MOCK } from "../consts";
import {
  AssetType,
  BoardAssetType,
  MyAssetType,
} from "types";
import { LocalStorageKeys } from "services/localStorage";
import ServiceProvider from "services/provider";

export const getResultsByAssetType = (assetType: AssetType | BoardAssetType | MyAssetType) => {
  switch (assetType) {
  case BoardAssetType.PrivateAssets:
    return PRIVATE_ASSETS_MOCK;
  case BoardAssetType.MediationAssets: 
    return MEDIATION_ASSETS_MOCK;
  case BoardAssetType.CommercialAssets: 
    return COMMERCIAL_ASSETS_MOCK;
  case AssetType.BoardAssets:
    return [...PRIVATE_ASSETS_MOCK, ...MEDIATION_ASSETS_MOCK, ...COMMERCIAL_ASSETS_MOCK];
  case MyAssetType.MyAssets: 
    return ServiceProvider.LocalStorage.getItem(LocalStorageKeys.MyAssets) ?? [];
  case MyAssetType.OfficeAssets: 
    return ServiceProvider.LocalStorage.getItem(LocalStorageKeys.OfficeAssets) ?? [];
  case MyAssetType.TrackingAssets: 
    return ServiceProvider.LocalStorage.getItem(LocalStorageKeys.TrackingAssets) ?? [];
  case AssetType.PrivateAssets:
    return [
      ...(ServiceProvider.LocalStorage.getItem(LocalStorageKeys.MyAssets) ?? []),
      ...(ServiceProvider.LocalStorage.getItem(LocalStorageKeys.OfficeAssets) ?? []),
      ...(ServiceProvider.LocalStorage.getItem(LocalStorageKeys.TrackingAssets) ?? []),
    ]
  default:
    return []
  }
}