import React, { useEffect } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { Card } from "flowbite-react";
import { SlClose } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentsHistoryLogAsync,
  makeHistoryLog,
} from "./reducer";
import { SearchSidebarSkeleton } from "components/Loader/Skeleton";
import { AppDispatch } from "../../store";

const CARD_THEME = {
  root: {
    base: "flex rounded-lg border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800",
    children: "flex h-full flex-col justify-start gap-4",
  },
};

const DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const HistoryLogSidebar = ({
  isOpen,
  onClose,
}: IProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const historyLog = useSelector(makeHistoryLog);

  useEffect(() => {
    dispatch(getDocumentsHistoryLogAsync());
  }, [dispatch]);

  const toggleSidebar = () => {
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={toggleSidebar}
        ></div>
      )}
      <Card
        theme={CARD_THEME}
        className={clsx("sidebar inset-y-0 w-[20rem] shadow-lg z-20 pt-4", {
          open: isOpen,
        })}
      >
        <div className="flex flex-col p-2">
          <SlClose onClick={toggleSidebar} className="w-5 h-5 self-end mr-4" />

          {historyLog.loading ? (
            <SearchSidebarSkeleton />
          ) : (
            historyLog.data.map((historyItem) => (
              <div className="border-b">
                <p>{historyItem.name}</p>
                <p className='text-xs text-slate-600'>Customer: {historyItem.customer.join(', ')}</p>
                <p className='text-xs text-slate-600'>Action: {historyItem.action}</p>
                <p className='text-xs text-slate-600'>Date: {dayjs(historyItem.date).format(DATE_TIME_FORMAT)}</p>
                <p className='text-xs text-slate-600'>Status: {historyItem.status}</p>
              </div>
            ))
          )}
        </div>
      </Card>
    </>
  );
};

export default HistoryLogSidebar;
