import React, { useCallback, useEffect, useState } from "react";
import { DocumentCardQuickActions, IDocument, INote } from "types";
import IconButton from "components/IconButton/IconButton";
import ServiceProvider from "services/provider";
import QuickActionsContent from "./QuickActionsContent";
import DocumentCardContent from "./DocumentCardContent";

interface IProps {
  data: IDocument;
  onMimimize: () => void;
  refetchDocumentsData: () => void;
}

const DocumentCard = ({ data, onMimimize, refetchDocumentsData }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [tags, setTags] = useState<string[]>([]);
  const [notes, setNotes] = useState<INote[]>([]);

  const [activeQuickAction, setActiveQuickAction] = useState<DocumentCardQuickActions>(
    DocumentCardQuickActions.Tag
  );

  const getAllTags = useCallback(() => {
    ServiceProvider.Tag.getById(data.id)
      .then((tags) => {
        setTags(tags.data);
      })
  }, [data.id])

  const getAllNotes = useCallback(() => {
    ServiceProvider.Note.getPropertyAll(data.id)
      .then((notes) => {
        setNotes(notes.data);
      })
  }, [data.id])

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getAllTags(),
      getAllNotes(),
    ]).then(() => setIsLoading(false))
  }, [getAllNotes, getAllTags]);

  const handleAddTag = (newTagText: string) => {
    setIsLoading(true);
    ServiceProvider.Tag.addById(newTagText, data.id)
      .then(() => {
        getAllTags();
        setIsLoading(false);
      });
  }

  const handleDeleteTag = (tagText: string) => {
    setIsLoading(true);
    ServiceProvider.Tag.removeById(tagText, data.id)
      .then(() => {
        getAllTags();
        setIsLoading(false);
      });
  }

  const handleAddNote = (text: string) => {
    ServiceProvider.Note.addToProperty(text, data.id)
      .then(() => {
        getAllNotes();
      });
  }

  const handleDeleteNote = (noteId: string) => {
    ServiceProvider.Note.deleteById(noteId, data.id)
      .then(() => {
        getAllNotes();
      });
  }

  const handleUpdateNote = (noteId: string, newText: string) => {
    ServiceProvider.Note.updateById(noteId, newText, data.id)
      .then(() => {
        getAllNotes();
      });
  }

  return (
    <>
      <div className="flex justify-between w-full items-center my-[1.25rem]">
        <QuickActionsContent 
          activeQuickAction={activeQuickAction} 
          handleAddNote={handleAddNote}
          isLoading={isLoading}
          tags={tags}
          handleAddTag={handleAddTag}
          handleDeleteTag={handleDeleteTag}
        />
        <div>
          <IconButton icon="CloseSquare" onClick={onMimimize} />
        </div>
      </div>
      <DocumentCardContent
        data={data}
        notes={notes}
        handleDeleteNote={handleDeleteNote}
        handleUpdateNote={handleUpdateNote}
        setActiveQuickAction={setActiveQuickAction}
        isLoading={isLoading}
        refetchDocumentsData={refetchDocumentsData}
      />
    </>
  );
};

export default DocumentCard;
