import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "services/provider";
import { getError } from "utils/errors";
import {
  ISignature,
} from "types";

export interface ISignaturesDataSource {
  data: ISignature[];
  count: number;
  loading: boolean;
}

export interface SignaturesStore {
  items: ISignaturesDataSource;
  error: string | null;
}

const initialState: SignaturesStore = {
  items: { data: [], count: 0, loading: false },
  error: null,
};

export const getSignaturesDataSourceAsync = createAsyncThunk(
  "property/getSignaturesItems",
  async () => {
    return await ServiceProvider.Signatures.getAll();
  }
);

export const signaturesSlice = createSlice({
  name: "signatures",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSignaturesDataSourceAsync.pending, (state) => {
        state.items.loading = true;
      })
      .addCase(getSignaturesDataSourceAsync.fulfilled, (state, action) => {
        const response = get(action, "payload", {
          count: 0,
          results: [],
        });
        state.items.data = response.results;
        state.items.count = response.count;
        state.items.loading = false;
      })
      .addCase(getSignaturesDataSourceAsync.rejected, (state, action) => {
        state.items.data = [];
        state.items.count = 0;
        state.items.loading = false;
        state.error = getError(action);
      });
  },
});

export const makeSignaturesDataSource = (state: { signatures: SignaturesStore }) =>
  state.signatures.items;

export default signaturesSlice.reducer;
