import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get } from "lodash";
import ServiceProvider from "services/provider";
import { getError } from "utils/errors";
import {
  DocumentStatus,
  IDocument,
} from "types";

export interface IHistoryItem {
  name: string;
  customer: string[];
  action: string;
  date: Date;
  status: DocumentStatus;
}

export interface IDocumentsDataSource {
  data: IDocument[];
  count: number;
  loading: boolean;
}

export interface DocumentsStore {
  items: IDocumentsDataSource;
  error: string | null;
  historyLog: {
    data: IHistoryItem[];
    loading: boolean;
    error: string | null;
  }
}

const initialState: DocumentsStore = {
  items: { data: [], count: 0, loading: false },
  error: null,
  historyLog: {
    data: [],
    loading: false,
    error: null,
  }
};

export const getDocumentsDataSourceAsync = createAsyncThunk(
  "property/getDocumentsItems",
  async () => {
    return await ServiceProvider.Documents.getAll();
  }
);

export const getDocumentsHistoryLogAsync = createAsyncThunk(
  "property/getDocumentsHistoryLog",
  async () => {
    return await ServiceProvider.Documents.getHistoryLog();
  }
);

export const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentsDataSourceAsync.pending, (state) => {
        state.items.loading = true;
      })
      .addCase(getDocumentsDataSourceAsync.fulfilled, (state, action) => {
        const response = get(action, "payload", {
          count: 0,
          results: [],
        });
        state.items.data = response.results;
        state.items.count = response.count;
        state.items.loading = false;
      })
      .addCase(getDocumentsDataSourceAsync.rejected, (state, action) => {
        state.items.data = [];
        state.items.count = 0;
        state.items.loading = false;
        state.error = getError(action);
      })
      .addCase(getDocumentsHistoryLogAsync.pending, (state) => {
        state.historyLog.loading = true;
      })
      .addCase(getDocumentsHistoryLogAsync.fulfilled, (state, action) => {
        const response = action.payload;
        state.historyLog.data = response;
        state.historyLog.loading = false;
      })
      .addCase(getDocumentsHistoryLogAsync.rejected, (state, action) => {
        state.historyLog.data = [];
        state.historyLog.loading = false;
        state.historyLog.error = getError(action);
      })
  },
});

export const makeDocumentsDataSource = (state: { documents: DocumentsStore }) =>
  state.documents.items;

export const makeHistoryLog = (state: { documents: DocumentsStore }) => state.documents.historyLog;

export default documentsSlice.reducer;
