import BaseModel from "./base";
import { IDictionary } from "types";

class CreateCustomerModel extends BaseModel {
  fullName: string;
  sex: string;
  email: string;
  phone: string;
  address: string;
  description: string;
  groupIds: string[];
  roomsFrom: string;
  roomsTo: string;
  sqmFrom: string;
  sqmTo: string;
  floorFrom: string;
  floorTo: string;
  priceFrom: string;
  priceTo: string;

  constructor(customer: IDictionary<string | string[]>, msg: IDictionary<string>) {
    super(msg);

    this.fullName = typeof customer.fullName === 'string' ? customer.fullName : "";
    this.sex = typeof customer.sex === 'string' ? customer.sex : "";
    this.email = typeof customer.email === 'string' ? customer.email : "";
    this.phone = typeof customer.phone === 'string' ? customer.phone : "";
    this.address = typeof customer.address === 'string' ? customer.address : "";
    this.description = typeof customer.description === 'string' ? customer.description : "";
    this.groupIds = Array.isArray(customer.groupIds) ? customer.groupIds : [];
    this.roomsFrom = typeof customer.roomsFrom === 'string' ? customer.roomsFrom : "";
    this.roomsTo = typeof customer.roomsTo === 'string' ? customer.roomsTo : "";
    this.sqmFrom = typeof customer.sqmFrom === 'string' ? customer.sqmFrom : "";
    this.sqmTo = typeof customer.sqmTo === 'string' ? customer.sqmTo : "";
    this.floorFrom = typeof customer.floorFrom === 'string' ? customer.floorFrom : "";
    this.floorTo = typeof customer.floorTo === 'string' ? customer.floorTo : "";
    this.priceFrom = typeof customer.priceFrom === 'string' ? customer.priceFrom : "";
    this.priceTo = typeof customer.priceTo === 'string' ? customer.priceTo : "";

    this.validationMsgs = msg;
    this.validationRules = {
      fullName: {
        presence: { allowEmpty: false, message: msg.fullName },
      },
      sex: {
        presence: { allowEmpty: false, message: msg.sex },
      },
      email: {
        presence: { allowEmpty: false, message: msg.email },
        email: { message: msg.invalidEmail },
      },
      phone: {
        presence: { allowEmpty: false, message: msg.phone },
      },
      address: {
        presence: { allowEmpty: false, message: msg.address },
      },
    };
  }
}

export default CreateCustomerModel;
