import { DataLoader } from "./Default";

const Row = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div className={`row flex justify-items-stretch gap-2 ${className}`}>
    {children}
  </div>
);

export const SearchSidebarSkeleton = () => (
  <div className="flex flex-col gap-4">
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
    <Row>
      <DataLoader className="min-w-[100%]" />
    </Row>
  </div>
);
