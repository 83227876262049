import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { IDocument } from "types";
import dayjs from "dayjs";
import DocumentCard from "./DocumentCard";

const DATE_FORMAT = "MM/DD/YYYY";

interface IProps {
  dataSource: IDocument[];
  refetchDocumentsData: () => void;
  selectedDocumentId: string | null;
  setSelectedDocumentId: Dispatch<SetStateAction<string | null>>;
}

const DocumentsTable = ({
  dataSource,
  refetchDocumentsData,
  selectedDocumentId,
  setSelectedDocumentId,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4 w-full">
      {!dataSource.length ? (
        <div className="text-center w-full text-slate-400 mt-4">
          {t("components.DocumentsTable.NoData")}
        </div>
      ) : (
        <div className="grid grid-cols-5 gap-4 border-b-2 py-2">
          {[
            "components.DocumentsTable.Fields.Name",
            "components.DocumentsTable.Fields.Customer",
            "components.DocumentsTable.Fields.Phone",
            "components.DocumentsTable.Fields.Date",
            "components.DocumentsTable.Fields.Status",
          ].map((title) => (
            <b key={title} className="text-left font-medium text-slate-600">
              {t(title)}
            </b>
          ))}
        </div>
      )}
      <div className="flex flex-col items-center w-full">
        {dataSource.map((rowData) => {
          return selectedDocumentId === rowData.id ? (
            <DocumentCard 
              key={rowData.id} 
              data={rowData} 
              onMimimize={() => setSelectedDocumentId(null)}
              refetchDocumentsData={refetchDocumentsData}
            />
          ) : (
            <div
              className="grid grid-cols-5 gap-4 w-full p-4 bg-white rounded-md hover:shadow-md transition-all duration-150 hover:bg-gray-50 cursor-pointer border-b"
              key={rowData.id}
              onClick={() => setSelectedDocumentId(rowData.id)}
            >
              {[
                rowData.name,
                rowData.clients.join(', '),
                rowData.phone,
                dayjs(rowData.createdAt).format(DATE_FORMAT),
                rowData.status,
              ].map((val, index) => (
                <div className="text-gray-700 text-sm" key={`${rowData.id}-${index}`}>
                  {val}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentsTable;
