import clsx from "clsx";
import { TextInput } from "flowbite-react";
import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

interface DetailsLabelProps {
  className?: string;
  title: string;
  id?: string;
  value: any;
  isTooltip?: boolean;
  enableEdit?: boolean;
  handleUpdateProperty?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const DetailsLabel: React.FC<DetailsLabelProps> = ({
  title,
  id,
  value,
  className,
  isTooltip,
  enableEdit = false,
  handleUpdateProperty,
}) => {
  const { t } = useTranslation();

  return (
    <div className={clsx("flex flex-col gap-2", className)}>
      <div className="text-sm font-semibold">{t(title)}</div>
      {enableEdit ? (
        <TextInput placeholder="Enter Task" id={id} value={value} onChange={handleUpdateProperty} />
      ) : (
        <div
          className="text-slate-600 text-sm line-clamp-3 cursor-default h-[2.625rem]"
          title={value.toString()}
        >
          {/* <Tooltip content={value} style="light" className="font-normal"> */}
          {value}
          {/* </Tooltip> */}
        </div>
      )}
    </div>
  );
};

export default DetailsLabel;
