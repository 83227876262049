import React from "react";

import { IDocument } from "types";
import DetailsLabel from "components/DetailsLabel";
import dayjs from "dayjs";

interface IProps {
  data: IDocument;
}

const DATE_FORMAT = "MM/DD/YYYY";

const DetailsTab = ({ data }: IProps) => {
  return (
    <div className="flex flex-col p-6">
      <div className="flex gap-4 justify-between">
        <DetailsLabel
          title="components.DocumentCard.DetailsTab.Name"
          value={data.name}
        />
        <DetailsLabel
          title="components.DocumentCard.DetailsTab.Customer"
          value={data.clients.join(', ')}
        />
        <DetailsLabel
          title="components.DocumentCard.DetailsTab.Date"
          value={data.phone}
        />
        <DetailsLabel
          title="components.DocumentCard.DetailsTab.Phone"
          value={dayjs(data.createdAt).format(DATE_FORMAT)}
        />
        <DetailsLabel
          title="components.DocumentCard.DetailsTab.Status"
          value={data.status}
        />
      </div>
    </div>
  );
};

export default DetailsTab;
