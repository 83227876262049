import { useEffect, useState } from "react";
import { NavLink, Navigate, Outlet, useLocation } from "react-router-dom";

import Logo from "assets/images/logo.png";
import { Pages } from "../../enums/pages";
import { useAuth } from "hooks/useAuth";
import LanguageSwitcher from "../LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { TopNavigationBar } from "./TopNavigationBar";
import { Iconly } from "react-iconly";

export const AuthorizedLayout = () => {
  const [topBarTitle, setTopBarTitle] = useState("");
  const { t, i18n } = useTranslation();

  const { user, setLocationToNavigate } = useAuth();
  const location = useLocation();

  useEffect(() => {
    document.body.dir = i18n.language === "he" ? "rtl" : "ltr";
  }, [i18n.language]);

  useEffect(() => {
    if (!user) {
      setLocationToNavigate(
        location.pathname === Pages.Logout ? Pages.Assets : location.pathname
      );
    }
  }, [user, setLocationToNavigate, location.pathname]);

  if (!user) {
    return <Navigate to={Pages.Login} />;
  }

  return (
    <div
      className={`flex flex-col gap-8 h-full m-auto w-[80%] border border-lightgray border-t-0 ${
        i18n.language === "he" ? "rtl" : "ltr"
      }`}
    >
      <div className="bg-white border-b">
        <div className="flex items-center justify-between h-20 w-full relative">
          <div className="border-r rtl:border-l h-full flex items-center justify-center px-8">
            <img src={Logo} className="h-10" alt="logo" />
          </div>
          <TopNavigationBar />
          <div className="flex gap-5 pr-[3.75rem] rtl:pl-[3.75rem]">
            <LanguageSwitcher />
            <span className="text-gray-200">|</span>
            <div className="flex gap-2 items-center">
              <Iconly name="Logout" />
              <NavLink to={Pages.Logout} className="text-primary font-medium">
                {t("AuthorizedLayout.Logout")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto">
        <Outlet context={[setTopBarTitle]} />
      </div>
    </div>
  );
};
