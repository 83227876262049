import { MouseEventHandler } from "react";

interface IProps {
  image: string, 
  title: string, 
  isMain?: boolean,
  handleClick: MouseEventHandler<HTMLDivElement>,
  deleteSignature?: React.ReactNode,
}

function SignatureItem({ image, title, isMain = false, handleClick, deleteSignature }: IProps) {
  return (
    <div 
      className={`p-2 flex flex-col gap-4 items-center border-2 rounded w-40 cursor-pointer ${isMain ? 'border-highlighted' : ''}`}
      onClick={handleClick}
    >
      <img src={image} className="w-full" alt="property" />
      <div className='w-full flex justify-between'>
        <p>{title}</p>
        {deleteSignature}
      </div>
    </div>
  );
}

export default SignatureItem;