import { Button } from "flowbite-react";
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next";

interface IProps {
  onSaveProvisional: (signatureImage: string) => void;
  onClear: () => void;
  handleSaveSignature: () => void;
  isSubmitDisabled: boolean;
}

function SignatureCanvas({ onSaveProvisional, onClear, handleSaveSignature, isSubmitDisabled }: IProps) {
  const { t } = useTranslation();
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [isDrawing, setIsDrawing] = useState(false)
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d')
      if (context) {
        context.lineWidth = 2
        context.strokeStyle = '#000000'
      }
    }
  }, [])
  
  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d')
      const rect = canvas.getBoundingClientRect()
      if (context) {
        context.beginPath()
        context.moveTo(e.clientX - rect.left, e.clientY - rect.top)
        setIsDrawing(true)
      }
    }
  }
  
  const draw = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d')
      const rect = canvas.getBoundingClientRect()
      if (context) {
        context.lineTo(e.clientX - rect.left, e.clientY - rect.top)
        context.stroke()
      }
    }
  }
  
  const stopDrawing = () => {
    if (isDrawing) {
      setIsDrawing(false)
      const canvas = canvasRef.current
      if (canvas) {
        const signatureImage = canvas.toDataURL()
        onSaveProvisional(signatureImage)
      }
    }
  }
  
  const clearCanvas = () => {
    const canvas = canvasRef.current
    if (canvas) {
      const context = canvas.getContext('2d')
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height)
        onClear()
      }
    }
  }

  const handleSubmitForm = () => {
    handleSaveSignature();
    clearCanvas();
  }
  
  return (
    <div className="flex flex-col items-start gap-4">
      <canvas
        ref={canvasRef}
        width={300}
        height={150}
        className="border border-gray-300"
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
      />
      <div className='flex gap-4'>
        <Button
          color="ghost"
          className="w-[10rem]"
          onClick={clearCanvas}
        >
          {t("pages.Signatures.form.clearSignature")}
        </Button>
        <Button
          color="cta"
          className="w-[10rem]"
          disabled={isSubmitDisabled}
          onClick={handleSubmitForm}
        >
          {t("pages.Signatures.form.save")}
        </Button>
      </div>
    </div>
  )
}

export default SignatureCanvas;