import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import reportWebVitals from "./reportWebVitals";
import i18n from "./i18n";
import { AuthProvider } from "./hooks/useAuth";
import { AuthorizedLayout } from "./components/AuthorizedLayout/AuthorizedLayout";
import { Pages } from "./enums/pages";
import { Provider } from "react-redux";
import { store } from "./store";
import { UnauthorizedLayout } from "./components/UnauthorizedLayout/UnauthorizedLayout";
import "./index.css";
import Theme from "./providers/theme";
import Calendar from "./pages/Calendar/Calendar";
import { Loader } from "./components/Loader/Loader";
import { ToastProvider } from "./components/ToastProvider/ToastProvider";
import CreateCustomer from "./pages/CreateCustomer/CreateCustomer";
import Documents from "./pages/Documents";
import Signatures from "./pages/Signatures";
import CreateDocument from "./pages/CreateDocument";

const Login = lazy(() => import("./pages/Login/Login"));
const CreateAsset = lazy(() => import("./pages/CreateAsset/CreateAsset"));
const Customers = lazy(() => import("./pages/Customers"));
const Customer = lazy(() => import("./pages/Customer"));
const Assets = lazy(() => import("./pages/Assets"));
const Asset = lazy(() => import("./pages/Asset/Asset"));
const Logout = lazy(() => import("./pages/Logout/Logout"));
const RegisterAgency = lazy(
  () => import("./pages/RegisterAgency/RegisterAgency")
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const withAuthProvider = (children: React.ReactNode) => (
  <AuthProvider>{children}</AuthProvider>
);

const router = createBrowserRouter([
  {
    element: withAuthProvider(<AuthorizedLayout />),
    children: [
      {
        path: Pages.RegisterAgency,
        element: <RegisterAgency />,
      },
      {
        path: Pages.Assets,
        element: <Assets />,
      },
      {
        path: `${Pages.Asset}/:id/:assetType`,
        element: <Asset />,
      },
      {
        path: `${Pages.CreateAsset}/:assetType`,
        element: <CreateAsset />,
      },
      {
        path: Pages.Customers,
        element: <Customers />,
      },
      {
        path: `${Pages.Customer}/:id/:customerType`,
        element: <Customer />,
      },
      {
        path: `${Pages.CreateCustomer}/:customerType`,
        element: <CreateCustomer />,
      },
      {
        path: Pages.Calendar,
        element: <Calendar />,
      },
      {
        path: Pages.Documents,
        element: <Documents />,
      },
      {
        path: `${Pages.Document}/:id/:documentStatus`,
        element: <Customer />,
      },
      {
        path: Pages.CreateDocument,
        element: <CreateDocument />,
      },
      {
        path: Pages.Signatures,
        element: <Signatures />,
      },
      {
        path: Pages.Logout,
        element: <Logout />,
      },
    ],
  },
  {
    element: withAuthProvider(<UnauthorizedLayout />),
    children: [
      {
        path: Pages.Login,
        element: <Login />,
      },
    ],
  },
]);

root.render(
  <React.StrictMode>
    <Theme>
      <I18nextProvider i18n={i18n}>
        <ToastProvider>
          <Provider store={store}>
            <Suspense
              fallback={
                <div className="flex h-full w-full justify-center items-center">
                  <Loader />
                </div>
              }
            >
              <RouterProvider router={router} />
            </Suspense>
          </Provider>
        </ToastProvider>
      </I18nextProvider>
    </Theme>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
