import {
  IAsset,
  PropertyFilter,
  ClientMatch,
  SignedClientMatch,
  BaseSelectOption,
  AssetType,
  IAssetMatch,
  IHousingPreferences,
  BoardAssetType,
  MyAssetType,
} from "types";
import { getBuyersMatches } from "utils/getBuyersMatches";
import { getResultsByAssetType } from "utils/getResultsByAssetType";
import BaseService from "./baseService";

export default class AssetsService extends BaseService {
  constructor() {
    super("property");
  }

  async getAssetTypes(): Promise<BaseSelectOption[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              name: "Garage",
            },
            {
              id: "2",
              name: "House",
            },
          ]),
        1000
      );
    });
  }

  async getClientMatches(propertyId: string): Promise<{
    clientMatch: ClientMatch[];
    signedClientMatch: SignedClientMatch[];
  }> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            clientMatch: [
              {
                id: "1",
                name: "Paul Pogba",
                ratio: 0.72,
              },
              {
                id: "1",
                name: "Erling Holand",
                ratio: 0.44,
              },
              {
                id: "3",
                name: "Eric Cantona",
                ratio: 0.87,
              },
              {
                id: "4",
                name: "Eric Cantona",
                ratio: 0.87,
              },
              {
                id: "5",
                name: "Clarence Seedorf",
                ratio: 0.67,
              },
            ],
            signedClientMatch: [
              {
                id: "1",
                name: "Arnold Dlonra",
              },
            ],
          }),
        1000
      );
    });
  }

  async getByType(
    assetType: AssetType | BoardAssetType | MyAssetType,
    filter: PropertyFilter | null
  ): Promise<{ count: number; results: IAsset[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = getResultsByAssetType(assetType);
        resolve({
          count: results.length,
          results,
        });
      }, 1000);
    });
  }

  async getMatches(filter: IHousingPreferences): Promise<{ 
    count: number; 
    results: IAssetMatch; 
  }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const results = getBuyersMatches(filter);
        resolve({
          count: results.board.length + results.private.length + results.mls.length,
          results,
        });
      }, 1000);
    });
  }
}
