import AuthService from "./authService";
import CustomerService from "./customerService";
import CalendarService from "./calendarService";
import CityService from "./cityService";
import DocumentsService from "./documentsService";
import ExtraService from "./extraService";
import LocalStorageService from "./localStorage";
import NeighborhoodService from "./neighborhoodService";
import NotesService from "./notesService";
import AssetsService from "./assetsService";
import TagService from "./tagService";
import TasksService from "./tasksService";
import SignaturesService from "./signaturesService";

export default class ServiceProvider {
  static Auth = new AuthService();
  static Tag = new TagService();
  static Assets = new AssetsService();
  static City = new CityService();
  static Neighborhood = new NeighborhoodService();
  static Extra = new ExtraService();
  static Note = new NotesService();
  static Task = new TasksService();
  static Documents = new DocumentsService();
  static Signatures = new SignaturesService();
  static Customer = new CustomerService();
  static Calendar = new CalendarService();
  static LocalStorage = new LocalStorageService();
}
