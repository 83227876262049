import React from "react";

import { IDocument } from "types";
import { Button, TextInput } from "flowbite-react";

interface IProps {
  data: IDocument;
}

const CommunicationTab = ({ data }: IProps) => {
  return (
    <div className="flex flex-col gap-4 p-6">
      <div className="p-2 rounded-lg bg-blue-100 text-blue-800 mr-8">
        <p className="font-semibold">You</p>
        <p>Hello John, Ive sent you the contract for review.</p>
        <p className="text-xs text-gray-500">2024-05-24 11:05</p>
      </div>
      <div className="p-2 rounded-lg bg-gray-100 text-gray-800 mr-8">
        <p className="font-semibold">Client</p>
        <p>Thanks, Ill take a look.</p>
        <p className="text-xs text-gray-500">2024-05-24 14:30</p>
      </div>
      <div className="flex space-x-2">
        <TextInput
          className="grow"
          placeholder="Type your message..." 
          value="" 
        />
        <Button
          className="w-40"
          color="cta"
        >
          Send
        </Button>
      </div>
    </div>
  );
};

export default CommunicationTab;