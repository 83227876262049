import { ReactComponent as Checkbox } from "assets/images/checkbox.svg";
import ClickableIcon from "components/ClickableIcon/ClickableIcon";
import { useTranslation } from "react-i18next";
import { DocumentCardQuickActions } from "types";

const QuickActions = ({
  onChange,
}: {
  onChange: (opt: DocumentCardQuickActions) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ClickableIcon
        icon={<Checkbox />}
        onClick={() => onChange(DocumentCardQuickActions.Tag)}
        title={t("components.QuickActions.tags")}
      />
      <ClickableIcon
        icon="Ticket"
        onClick={() => onChange(DocumentCardQuickActions.Notes)}
        title={t("components.QuickActions.notes")}
      />
      <ClickableIcon
        icon="Delete"
        onClick={() => onChange(DocumentCardQuickActions.Delete)}
        title={t("components.QuickActions.delete")}
      />
    </>
  );
};

export default QuickActions;
