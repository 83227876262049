import CalendarPicker from 'react-calendar';
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { CalendarEvent } from "types";
import ServiceProvider from "services/provider";
import { Loader } from "components/Loader/Loader";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { ReactComponent as MaximizeIcon } from "assets/images/maximize.svg";
import 'react-calendar/dist/Calendar.css';
import "./Calendar.css";
import CustomToolbar from './customCalendarComponents/CustomToolbar';
import CustomWeekViewHeader from './customCalendarComponents/CustomWeekViewHeader';
import CustomTimeSlotWrapper from './customCalendarComponents/CustomTimeSlotWrapper';
import IconButton from 'components/IconButton/IconButton';
import CustomSmallCalendarLabel from './customCalendarComponents/CustomSmallCalendarLabel';

const localizer = dayjsLocalizer(dayjs);

type CalendarPickerValuePiece = Date | null;

type CalendarPickerValue = CalendarPickerValuePiece 
  | [CalendarPickerValuePiece, CalendarPickerValuePiece];

const CalendarPage = () => {
  const [date, setDate] = useState<CalendarPickerValue>(new Date());
  const [isSmallCalendarVisible, setSmallCalendarVisible] = useState(true);

  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    ServiceProvider.Calendar.getAll("123")
      .then(setEvents)
      .finally(() => setLoading(false));
  }, []);

  const components = useMemo(() => ({
    toolbar: CustomToolbar,
    week: {
      header: CustomWeekViewHeader,
    },
    timeSlotWrapper: CustomTimeSlotWrapper,
  }), []);

  return (
    <div className="px-12">
      {loading ? (
        <Loader />
      ) : (
        <div 
          className={`grid ${isSmallCalendarVisible ? 'grid-cols-[20rem_auto]' : 'grid-cols-[3rem_auto]'} gap-10`}
        >
          <div>
            {isSmallCalendarVisible ? (
              <CalendarPicker 
                onChange={setDate} 
                value={date}
                next2Label={null}
                prev2Label={null}
                prevLabel={<IoIosArrowBack />}
                nextLabel={<IoIosArrowForward />}
                navigationLabel={({ label }) => (
                  <CustomSmallCalendarLabel 
                    label={label} 
                    setSmallCalendarVisible={setSmallCalendarVisible} 
                  />
                )}
              />
            ) : (
              <IconButton
                icon={<MaximizeIcon />}
                onClick={() => setSmallCalendarVisible(true)} 
              />
            )}
          </div>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 'calc(100vh - 10rem)', width: '100%' }}
            components={components}
            scrollToTime={new Date()}
          />
        </div>
      )}
    </div>
  );
};

export default CalendarPage;
