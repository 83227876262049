import { useTranslation } from "react-i18next";

import { Button, Select, TextInput } from "flowbite-react";
import LabeledInput from "components/LabeledInput/LabeledInput";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import CreateDocumentModel from "../../models/createDocument";
import { validateModel } from "utils/form";
import { useToast } from "components/ToastProvider/ToastProvider";
import { useNavigate } from "react-router-dom";
import { RECEIPTS } from "../../consts";
import ServiceProvider from "services/provider";
import { LocalStorageKeys } from "services/localStorage";
import { Pages } from "../../enums/pages";
import { DocumentStatus } from "types";
import { useDispatch, useSelector } from "react-redux";
import { getCustomersDataSourceAsync, makeCustomerDataSource } from "../Customers/reducer";
import { AppDispatch } from "../../store";

const changeValue = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  if (e.target.type === 'select-multiple') {
    const options = (e as ChangeEvent<HTMLSelectElement>).target.options;
    const selectedValues = Array.from(options)
      .filter(option => option.selected)
      .map(option => option.value);
    console.log(Array.from(options)
      .filter(option => option.selected).map(option => option.value))
    return selectedValues;
  }
  return e.target.value
}

function CreateDocument() {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const customersData = useSelector(makeCustomerDataSource);

  useEffect(() => {
    dispatch(getCustomersDataSourceAsync());
  }, [dispatch]);

  const [formData, setFormData] = useState<CreateDocumentModel>(
    new CreateDocumentModel({
      name: "",
      type: "",
      exclusivityStart: "",
      exclusivityEnd: "",
      price: "",
      address: "",
      zip: "",
      city: "",
      phone: "",
      budget: "",
      notes: "",
      receipts: [],
      clients: [],
    },
    {
      name: t("pages.CreateDocument.form.validation.name"),
      type: t("pages.CreateDocument.form.validation.type"),
      receipts: t("pages.CreateDocument.form.validation.receipts"),
      clients: t("pages.CreateDocument.form.validation.clients"),
    })
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prev) => new CreateDocumentModel(
      {
        name: prev.name,
        type: prev.type,
        exclusivityStart: prev.exclusivityStart,
        exclusivityEnd: prev.exclusivityEnd,
        price: prev.price,
        phone: prev.phone,
        address: prev.address,
        budget: prev.budget,
        zip: prev.zip,
        city: prev.city,
        notes: prev.notes,
        receipts: prev.receipts,
        clients: prev.clients,
        [e.target.id]: changeValue(e),
      },
      prev.validationMsgs
    ));
  };

  const handleSubmit = useCallback(() => {
    const { isModelValid, errors } = validateModel(formData);

    const documentData = Object.keys(formData).reduce((acc, key) => {
      const typedKey = key as keyof typeof formData;
      return {
        ...acc,
        ...['validationMsgs', 'validationRules', 'validate']
          .includes(key) ? {} : { [typedKey]: formData[typedKey] }
      }
    }, {})

    if (isModelValid) {
      ServiceProvider.LocalStorage.setItem(LocalStorageKeys.Documents, [
        ...(ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Documents) ?? []),
        // Temporary
        {
          ...documentData,
          id: `${Math.floor(Math.random() * 100000)}`,
          status: DocumentStatus.NeedAction,
          createdAt: new Date(),
        }
      ])
      ServiceProvider.LocalStorage.setItem(LocalStorageKeys.DocumentsHistoryLog, [
        ...(ServiceProvider.LocalStorage.getItem(LocalStorageKeys.DocumentsHistoryLog) ?? []),
        // Temporary
        {
          name: formData.name,
          customer: formData.clients,
          action: "Created",
          date: new Date(),
          status: DocumentStatus.NeedAction,
        }
      ])
      return navigate(Pages.Documents);
    } else {
      showToast(errors);
    }
  }, [formData, navigate, showToast]);

  return (
    <div className="flex flex-col gap-4 px-4">
      <div className="font-semibold text-lg">
        {t("pages.CreateDocument.title")}
      </div>
      <form className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <LabeledInput
            title={t("pages.CreateDocument.form.name.label")}
            id="name"
          >
            <TextInput
              placeholder={t("pages.CreateDocument.form.name.placeholder")}
              id="name"
              value={formData.name}
              onChange={(e) =>
                handleChange(e)
              }
            />
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateDocument.form.type.label")}
            id="type"
          >
            <Select id="type" value={formData.type} onChange={handleChange}>
              <option value="">Select document type</option>
              <option value="exclusivity">Exclusivity</option>
              <option value="contract">Contract</option>
              <option value="agreement">Agreement</option>
            </Select>
          </LabeledInput>
          {formData.type === 'exclusivity' && (
            <>
              <LabeledInput
                title={t("pages.CreateDocument.form.exclusivityStart.label")}
                id="exclusivityStart"
              >
                <TextInput
                  placeholder={t("pages.CreateDocument.form.exclusivityStart.placeholder")}
                  id="exclusivityStart"
                  value={formData.exclusivityStart}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateDocument.form.exclusivityEnd.label")}
                id="exclusivityEnd"
              >
                <TextInput
                  placeholder={t("pages.CreateDocument.form.exclusivityEnd.placeholder")}
                  id="exclusivityEnd"
                  value={formData.exclusivityEnd}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateDocument.form.price.label")}
                id="price"
              >
                <TextInput
                  placeholder={t("pages.CreateDocument.form.price.placeholder")}
                  id="price"
                  value={formData.price}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
            </>
          )}
          {formData.type === 'agreement' && (
            <LabeledInput
              title={t("pages.CreateDocument.form.phone.label")}
              id="phone"
            >
              <TextInput
                placeholder={t("pages.CreateDocument.form.phone.placeholder")}
                id="phone"
                value={formData.phone}
                onChange={(e) =>
                  handleChange(e)
                }
              />
            </LabeledInput>
          )}
          {['contract', 'agreement'].includes(formData.type) && (
            <LabeledInput
              title={t("pages.CreateDocument.form.address.label")}
              id="address"
            >
              <TextInput
                placeholder={t("pages.CreateDocument.form.address.placeholder")}
                id="address"
                value={formData.address}
                onChange={(e) =>
                  handleChange(e)
                }
              />
            </LabeledInput>
          )}
          {formData.type === 'contract' && (
            <>
              <LabeledInput
                title={t("pages.CreateDocument.form.zip.label")}
                id="zip"
              >
                <TextInput
                  placeholder={t("pages.CreateDocument.form.zip.placeholder")}
                  id="zip"
                  value={formData.zip}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateDocument.form.city.label")}
                id="city"
              >
                <TextInput
                  placeholder={t("pages.CreateDocument.form.city.placeholder")}
                  id="city"
                  value={formData.city}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
            </>
          )}
          {formData.type === 'agreement' && (
            <LabeledInput
              title={t("pages.CreateDocument.form.budget.label")}
              id="budget"
            >
              <TextInput
                placeholder={t("pages.CreateDocument.form.budget.placeholder")}
                id="budget"
                value={formData.budget}
                onChange={(e) =>
                  handleChange(e)
                }
              />
            </LabeledInput>
          )}
          <LabeledInput
            title={t("pages.CreateDocument.form.notes.label")}
            id="notes"
          >
            <TextInput
              placeholder={t("pages.CreateDocument.form.notes.placeholder")}
              id="notes"
              value={formData.notes}
              onChange={handleChange}
            />
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateDocument.form.receipts.label")}
            id="receipts"
          >
            <Select id="receipts" value={formData.receipts} onChange={handleChange} multiple>
              {RECEIPTS.map((receipt) => (
                <option key={receipt.id} value={receipt.id}>{receipt.label}</option>
              ))}
            </Select>
          </LabeledInput>
          {customersData.data.length ? (
            <LabeledInput
              title={t("pages.CreateDocument.form.clients.label")}
              id="clients"
            >
              <Select id="clients" value={formData.clients} onChange={handleChange} multiple>
                {customersData.data.map((customer) => (
                  <option key={customer.id} value={customer.id}>{customer.fullName}</option>
                ))}
              </Select>
            </LabeledInput> ) : (
            <div className="text-center w-full text-red-500 mt-4">
              {t("pages.CreateDocument.form.NoClients")}
            </div>
          )
          }
        </div>
        <Button
          color="cta"
          className="w-[10rem] self-end"
          onClick={handleSubmit}
        >
          {t("pages.CreateCustomer.form.submit")}
        </Button>
      </form>
    </div>
  );
}

export default CreateDocument;
