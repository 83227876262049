import { useTranslation } from "react-i18next";

import { Button, Select, TextInput } from "flowbite-react";
import LabeledInput from "components/LabeledInput/LabeledInput";
import { ChangeEvent, useCallback, useState } from "react";
import CreateCustomerModel from "../../models/createCustomer";
import { validateModel } from "utils/form";
import { useToast } from "components/ToastProvider/ToastProvider";
import ServiceProvider from "services/provider";
import { LocalStorageKeys } from "services/localStorage";
import { useNavigate, useParams } from "react-router-dom";
import { Pages } from "../../enums/pages";
import { CustomerType } from "types";
import { GROUPS } from "../../consts";

const changeValue = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  if (e.target.type === 'select-multiple') {
    return Array.from((e as ChangeEvent<HTMLSelectElement>).target.selectedOptions)
      .map(option => option.value);
  }
  return e.target.value
}


function CreateCustomer() {
  const { t } = useTranslation();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { customerType } = useParams();

  const [formData, setFormData] = useState<CreateCustomerModel>(
    new CreateCustomerModel({
      fullName: "",
      email: "",
      sex: "",
      phone: "",
      address: "",
      description: "",
      groupIds: [],
      roomsFrom: "",
      roomsTo: "",
      sqmFrom: "",
      sqmTo: "",
      floorFrom: "",
      floorTo: "",
      priceFrom: "",
      priceTo: "",
    },
    {
      fullName: t("pages.CreateCustomer.validation.fullName"),
      email: t("pages.CreateCustomer.validation.emailAddress"),
      invalidEmail: t("pages.CreateCustomer.validation.invalidEmail"),
      sex: t("pages.CreateCustomer.validation.sex"),
      phone: t("pages.CreateCustomer.validation.phone"),
      address: t("pages.CreateCustomer.validation.address"),
      description: t("pages.CreateCustomer.validation.description"),
      groupIds: t("pages.CreateCustomer.validation.groupIds"),
      roomsFrom: t("pages.CreateCustomer.validation.roomsFrom"),
      roomsTo: t("pages.CreateCustomer.validation.roomsTo"),
      sqmFrom: t("pages.CreateCustomer.validation.sqmFrom"),
      sqmTo: t("pages.CreateCustomer.validation.sqmTo"),
      floorFrom: t("pages.CreateCustomer.validation.floorFrom"),
      floorTo: t("pages.CreateCustomer.validation.floorTo"),
      priceFrom: t("pages.CreateCustomer.validation.priceFrom"),
      priceTo: t("pages.CreateCustomer.validation.priceTo"),
    })
  );

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData((prev) => new CreateCustomerModel(
      {
        fullName: prev.fullName,
        email: prev.email,
        sex: prev.sex,
        phone: prev.phone,
        address: prev.address,
        description: prev.description,
        roomsFrom: prev.roomsFrom,
        roomsTo: prev.roomsTo,
        sqmFrom: prev.sqmFrom,
        sqmTo: prev.sqmTo,
        floorFrom: prev.floorFrom,
        floorTo: prev.floorTo,
        priceFrom: prev.priceFrom,
        priceTo: prev.priceTo,
        [e.target.id]: changeValue(e),
      },
      prev.validationMsgs
    ));
  };

  const handleSubmit = useCallback(() => {
    const { isModelValid, errors } = validateModel(formData);

    let type: string | number | undefined = customerType

    if (!type) {
      type = CustomerType.Leads
    }

    const customerData = Object.keys(formData).reduce((acc, key) => {
      const typedKey = key as keyof typeof formData;
      return {
        ...acc,
        ...['validationMsgs', 'validationRules'].includes(key) ? {} : { [typedKey]: formData[typedKey] }
      }
    }, {})

    if (isModelValid) {
      ServiceProvider.LocalStorage.setItem(LocalStorageKeys.Customers, [
        ...(ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Customers) ?? []),
        // Temporary
        {
          ...customerData,
          type: +type,
          id: `${Math.floor(Math.random() * 100000)}`,
        }
      ])
      return navigate(Pages.Customers);
    } else {
      showToast(errors);
    }
  }, [customerType, formData, navigate, showToast]);

  return (
    <div className="flex flex-col gap-4 px-4">
      <div className="font-semibold text-lg">
        {t("pages.CreateCustomer.title")}
      </div>
      <form className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4">
          <LabeledInput
            title={t("pages.CreateCustomer.form.fullName.label")}
            id="fullName"
          >
            <TextInput
              placeholder={t("pages.CreateCustomer.form.fullName.placeholder")}
              id="fullName"
              value={formData.fullName}
              onChange={(e) =>
                handleChange(e)
              }
            />
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateCustomer.form.sex.label")}
            id="sex"
          >
            <Select id="sex" value={formData.sex} onChange={handleChange}>
              <option value="">Select sex</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </Select>
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateCustomer.form.email.label")}
            id="email"
          >
            <TextInput
              placeholder={t("pages.CreateCustomer.form.email.placeholder")}
              id="email"
              value={formData.email}
              onChange={(e) =>
                handleChange(e)
              }
            />
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateCustomer.form.phone.label")}
            id="phone"
          >
            <TextInput
              placeholder={t("pages.CreateCustomer.form.phone.placeholder")}
              id="phone"
              value={formData.phone}
              onChange={(e) =>
                handleChange(e)
              }
            />
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateCustomer.form.address.label")}
            id="address"
          >
            <TextInput
              placeholder={t("pages.CreateCustomer.form.address.placeholder")}
              id="address"
              value={formData.address}
              onChange={(e) =>
                handleChange(e)
              }
            />
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateCustomer.form.description.label")}
            id="description"
          >
            <TextInput
              placeholder={t("pages.CreateCustomer.form.description.placeholder")}
              id="description"
              value={formData.description}
              onChange={(e) =>
                handleChange(e)
              }
            />
          </LabeledInput>
          <LabeledInput
            title={t("pages.CreateCustomer.form.groups.label")}
            id="groupIds"
          >
            <Select id="groupIds" value={formData.groupIds} onChange={handleChange} multiple>
              {GROUPS.map((group) => (
                <option value={group.id}>{group.label}</option>
              ))}
            </Select>
          </LabeledInput>
        </div>
        { Number(customerType) === CustomerType.Buyers ? (
          <>
            <div className="font-semibold">
              {t("pages.CreateCustomer.housingPreferences")}
            </div>
            <div className="grid grid-cols-4 gap-4">
              <LabeledInput
                title={t("pages.CreateCustomer.form.roomsFrom.label")}
                id="roomsFrom"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.roomsFrom.placeholder")}
                  id="roomsFrom"
                  value={formData.roomsFrom}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateCustomer.form.roomsTo.label")}
                id="roomsTo"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.roomsTo.placeholder")}
                  id="roomsTo"
                  value={formData.roomsTo}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateCustomer.form.sqmFrom.label")}
                id="sqmFrom"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.sqmFrom.placeholder")}
                  id="sqmFrom"
                  value={formData.sqmFrom}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateCustomer.form.sqmTo.label")}
                id="sqmTo"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.sqmTo.placeholder")}
                  id="sqmTo"
                  value={formData.sqmTo}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateCustomer.form.floorFrom.label")}
                id="floorFrom"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.floorFrom.placeholder")}
                  id="floorFrom"
                  value={formData.floorFrom}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateCustomer.form.floorTo.label")}
                id="floorTo"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.floorTo.placeholder")}
                  id="floorTo"
                  value={formData.floorTo}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateCustomer.form.priceFrom.label")}
                id="priceFrom"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.priceFrom.placeholder")}
                  id="priceFrom"
                  value={formData.priceFrom}
                  onChange={handleChange}
                />
              </LabeledInput>
              <LabeledInput
                title={t("pages.CreateCustomer.form.priceTo.label")}
                id="priceTo"
              >
                <TextInput
                  placeholder={t("pages.CreateCustomer.form.priceTo.placeholder")}
                  id="priceTo"
                  value={formData.priceTo}
                  onChange={(e) =>
                    handleChange(e)
                  }
                />
              </LabeledInput>
            </div>
          </>
        ) : false}
        <Button
          color="cta"
          className="w-[10rem] self-end"
          onClick={handleSubmit}
        >
          {t("pages.CreateCustomer.form.submit")}
        </Button>
      </form>
    </div>
  );
}

export default CreateCustomer;
