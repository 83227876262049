import { useState } from "react";
import { Button, TextInput } from "flowbite-react";

import { DocumentCardQuickActions } from "types";

import TagInput from "components/TagInput/TagInput";
import { TAGS_MOCK } from "../../../consts";

interface Props {
  activeQuickAction: DocumentCardQuickActions,
  handleAddNote: (text: string) => void,
  tags: string[],
  handleAddTag: (text: string) => void,
  handleDeleteTag: (text: string) => void,
  isLoading?: boolean,
}

function QuickActionsContent({
  activeQuickAction, 
  handleAddNote,
  tags,
  handleAddTag,
  handleDeleteTag,
  isLoading = false,
}: Props) {
  const [newNoteText, setNewNoteText] = useState('');

  if (activeQuickAction === DocumentCardQuickActions.Notes) {
    return (
      <div className="flex gap-5 items-center relative w-[60%]">
        <TextInput 
          className="w-[60%]" 
          placeholder="Enter Notes" 
          value={newNoteText} 
          onChange={(e) => setNewNoteText(e.target.value)} 
        />
        <Button onClick={() => handleAddNote(newNoteText)} color="ghost">Submit</Button>
      </div>
    )
  }

  return (
    <TagInput
      availableTags={TAGS_MOCK}
      selectedTags={tags}
      onAdd={(tag) => handleAddTag(tag)}
      onRemove={(tag) => handleDeleteTag(tag)}
      isLoading={isLoading}
    />
  )
}


export default QuickActionsContent;