export function DefaultLoader({ className = "" }: { className?: string }) {
  return (
    <div role="status" className={`w-full max-w-sm animate-pulse ${className}`}>
      <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-2 max-w-[22.5rem] rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-2 max-w-[20.625rem] rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-2 max-w-[18.75rem] rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-2 max-w-[22.5rem] rounded-full bg-gray-200 dark:bg-gray-700"></div>
    </div>
  );
}

export const DataLoader = ({ className = "" }: { className?: string }) => (
  <div
    role="status"
    className={`max-w-lg animate-pulse space-y-2.5 ${className}`}
  >
    <div className="flex w-full items-center space-x-2">
      <div className="h-2.5 w-32 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-2.5 w-24 rounded-full bg-gray-300 dark:bg-gray-600"></div>
      <div className="h-2.5 w-full rounded-full bg-gray-300 dark:bg-gray-600"></div>
    </div>
    <div className="flex w-full max-w-[30rem] items-center space-x-2">
      <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-2.5 w-full rounded-full bg-gray-300 dark:bg-gray-600"></div>
      <div className="h-2.5 w-24 rounded-full bg-gray-300 dark:bg-gray-600"></div>
    </div>
    <div className="flex w-full max-w-[25rem] items-center space-x-2">
      <div className="h-2.5 w-full rounded-full bg-gray-300 dark:bg-gray-600"></div>
      <div className="h-2.5 w-80 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-2.5 w-full rounded-full bg-gray-300 dark:bg-gray-600"></div>
    </div>
    <div className="flex w-full max-w-[30rem] items-center space-x-2">
      <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-2.5 w-full rounded-full bg-gray-300 dark:bg-gray-600"></div>
      <div className="h-2.5 w-24 rounded-full bg-gray-300 dark:bg-gray-600"></div>
    </div>
    <div className="flex w-full max-w-[27.5rem] items-center space-x-2">
      <div className="h-2.5 w-32 rounded-full bg-gray-300 dark:bg-gray-600"></div>
      <div className="h-2.5 w-24 rounded-full bg-gray-300 dark:bg-gray-600"></div>
      <div className="h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"></div>
    </div>
    <div className="flex w-full max-w-[22.5rem] items-center space-x-2">
      <div className="h-2.5 w-full rounded-full bg-gray-300 dark:bg-gray-600"></div>
      <div className="h-2.5 w-80 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-2.5 w-full rounded-full bg-gray-300 dark:bg-gray-600"></div>
    </div>
    <span className="sr-only">Loading...</span>
  </div>
);

export function TaskLoader({ className = "" }: { className?: string }) {
  return (
    <div role="status" className={`w-full max-w-sm animate-pulse ${className}`}>
      <div className="mb-4 h-2.5 w-48 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-2 max-w-[22.5rem] rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="mb-2.5 h-2 rounded-full bg-gray-200 dark:bg-gray-700"></div>
      <div className="h-2 max-w-[22.5rem] rounded-full bg-gray-200 dark:bg-gray-700"></div>
    </div>
  );
}
