import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { Button, Card } from "flowbite-react";
import { DocumentCardQuickActions, DocumentStatus, IDocument, INote } from "types";
import Tabs, { CustomCta, Tab, useTabs } from "components/Tabs/Tabs";
import DetailsTab from "./tabs/DetailsTab";
import NotesTab from "./tabs/NotesTab";
import QuickActions from "./QuickActions";
import HistoryTab from "./tabs/HistoryTab";
import CommunicationTab from "./tabs/CommunicationTab";
import ReviewTab from "./tabs/ReviewTab";

const CARD_THEME = {
  root: {
    base: "flex rounded border border-gray-200 bg-white shadow-md dark:border-gray-700 dark:bg-gray-800 w-full shadow-mastercard-expand",
    children: "flex h-full flex-col justify-center p-2",
    horizontal: {
      off: "flex-col",
      on: "flex-col md:max-w-xl md:flex-row",
    },
    href: "hover:bg-gray-100 dark:hover:bg-gray-700",
  },
};

interface IProps {
  data: IDocument;
  notes: INote[];
  handleDeleteNote: (noteId: string) => void;
  handleUpdateNote: (noteId: string, newText: string) => void;
  setActiveQuickAction: Dispatch<SetStateAction<DocumentCardQuickActions>>;
  isLoading: boolean;
  refetchDocumentsData: () => void;
}

const DocumentCardContent = ({ 
  data, notes, handleDeleteNote, handleUpdateNote, setActiveQuickAction, isLoading, refetchDocumentsData,
}: IProps) => {
  const { t } = useTranslation();
  const { activeTab, setActiveTab } = useTabs();

  return (
    <Card theme={CARD_THEME}>
      {data.status === DocumentStatus.WaitingForSign ? (
        <div className='w-full my-4'>
          <div className='bg-lightgray flex gap-4 mx-16 rounded-md p-8 items-center'>
            <p>{t("components.DocumentCard.sentForSignature", { days: 155 })}</p>
            <Button
              color="primary"
              className="w-[11rem] self-end"
            >
              {t("components.DocumentCard.sendReminderButton")}
            </Button>
          </div>
        </div>
      ) : false}
      <p></p>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabButtonsContainerClassName="gap-2">
        {[
          {
            title: "components.DocumentCard.Details",
            content: <DetailsTab data={data} />,
          },
          {
            title: "components.DocumentCard.Notes",
            content: <NotesTab 
              notes={notes} 
              isLoading={isLoading} 
              handleDeleteNote={handleDeleteNote} 
              handleUpdateNote={handleUpdateNote}
            />,
          },
          {
            title: "components.DocumentCard.History",
            content: <HistoryTab data={data} />,
          },
          {
            title: "components.DocumentCard.Communication",
            content: <CommunicationTab data={data} />,
          },
          ...(data.status === DocumentStatus.NeedAction ? [{
            title: "components.DocumentCard.ReviewNow",
            content: <ReviewTab data={data} refetchDocumentsData={refetchDocumentsData} />,
          }] : []),
        ].map((v) => (
          <Tab label={t(v.title)} key={v.title} className="border-t">
            {v.content}
          </Tab>
        ))}
        <CustomCta className="flex gap-4 mr-5 cursor-pointer">
          <QuickActions onChange={setActiveQuickAction} />
        </CustomCta>
      </Tabs>
    </Card>
  );
};

export default DocumentCardContent;
