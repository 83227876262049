import React from "react";
import { Label } from "flowbite-react";
import clsx from "clsx";

interface LabeledInputProps {
  children: React.ReactElement;
  title: string;
  className?: string;
  id: string;
}

const LabeledInput: React.FC<LabeledInputProps> = ({
  children,
  title,
  id,
  className,
}) => {
  return (
    <div className={clsx("flex flex-col", className)}>
      <div className={"mb-2 block"}>
        <Label htmlFor={id} value={title} className="text-xs font-normal" />
      </div>
      {children}
    </div>
  );
};

export default LabeledInput;
