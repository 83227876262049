import React from "react";

import { INote } from "types";
import { DefaultLoader } from "components/Loader/Default";
import Note from "components/Note";
import { useTranslation } from "react-i18next";

interface IProps {
  notes?: INote[];
  isLoading?: boolean;
  handleDeleteNote: (noteId: string) => void;
  handleUpdateNote: (noteId: string, newText: string) => void;
}

const NotesTab = ({ notes, isLoading, handleDeleteNote, handleUpdateNote }: IProps) => {
  const { t } = useTranslation();

  if (!notes || !notes.length) return (
    <div className="text-center w-full text-slate-400 mt-4">
      {t("AssetsDataTable.NoNotes")}
    </div>
  );

  return (
    <div className="grid grid-cols-2 gap-4 master-card-container">
      {isLoading && <DefaultLoader className="m-auto" />}
      {!isLoading &&
        notes.map((note) => {
          return (
            <Note key={note.id} note={note} handleDeleteNote={handleDeleteNote} handleUpdateNote={handleUpdateNote} />
          );
        })}
    </div>
  );
};

export default NotesTab;