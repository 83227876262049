import React from "react";

import { IDocument } from "types";

interface IProps {
  data: IDocument;
}

const HistoryTab = ({ data }: IProps) => {
  return (
    <div className="flex flex-col p-6">
      <table className="w-full caption-bottom text-sm">
        <thead className="[&amp;_tr]:border-b">
          <tr className="border-b">
            <th className="h-10 px-2 text-left align-middle font-bold">
              Action
            </th>
            <th className="h-10 px-2 text-left align-middle font-bold">
              Date &amp; Time
            </th>
          </tr>
        </thead>
        <tbody className="[&amp;_tr:last-child]:border-0">
          <tr className="border-b">
            <td className="p-2 align-middle">
              Created
            </td>
            <td className="p-2 align-middle">
              2024-05-24 10:00
            </td>
          </tr>
          <tr className="border-b">
            <td className="p-2 align-middle">
              Sent
            </td>
            <td className="p-2 align-middle">
              2024-05-24 11:00
            </td>
          </tr>
          <tr className="border-b">
            <td className="p-2 align-middle">
              Viewed
            </td>
            <td className="p-2 align-middle">
              2024-05-24 14:30
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HistoryTab;