import React, { useCallback, useEffect, useState } from "react";

import { DocumentStatus, IDocument } from "types";
import SignatureItem from "components/SignatureItem";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import { getSignaturesDataSourceAsync, makeSignaturesDataSource } from "../../../Signatures/reducer";
import { Button } from "flowbite-react";
import ServiceProvider from "services/provider";
import { LocalStorageKeys } from "services/localStorage";

interface IProps {
  data: IDocument;
  refetchDocumentsData: () => void;
}

const ReviewTab = ({ data, refetchDocumentsData }: IProps) => {
  const dispatch = useDispatch<AppDispatch>();
  
  const signaturesData = useSelector(makeSignaturesDataSource);

  const getAllSignatures = useCallback(() => {
    dispatch(getSignaturesDataSourceAsync());
  }, [dispatch])

  useEffect(() => {
    getAllSignatures();
  }, [getAllSignatures]);

  const [selectedSignature, setSelectedSignature] = useState<string | null>(null);

  const signDocument = () => {
    ServiceProvider.LocalStorage.setItem(
      LocalStorageKeys.Documents,
      (ServiceProvider.LocalStorage.getItem(LocalStorageKeys.Documents) || []).map((document: IDocument) => {
        if (document.id === data.id) {
          return {
            ...document,
            status: DocumentStatus.WaitingForSign
          }
        }
        return document;
      }))
    refetchDocumentsData();
  }

  return (
    <div className="flex flex-col items-center p-6 gap-4">
      <div className="border border-gray-300 h-96 w-[80%] flex items-center justify-center">
        <p>PDF Viewer Placeholder</p>
      </div>
      <div className="flex flex-wrap items-start gap-4">
        {signaturesData.data.map((signature) => (
          <SignatureItem 
            key={signature.id}
            image={signature.image} 
            title={signature.title} 
            isMain={selectedSignature === signature.id} 
            handleClick={() => setSelectedSignature(signature.id)}
          />
        ))}
      </div>
      <Button
        className="w-40"
        color="cta"
        onClick={signDocument}
      >
        Sign
      </Button>
    </div>
  );
};

export default ReviewTab;
