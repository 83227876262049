import { Tag } from "types";
import BaseService from "./baseService";
import { LocalStorageKeys } from "./localStorage";
import ServiceProvider from "./provider";

export default class TagService extends BaseService {
  constructor() {
    super("tag");
  }

  async getAll(): Promise<Tag[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              name: "Tag 1",
              color: "#FFEEEE",
            },
            {
              id: "2",
              name: "Tag 2",
              color: "#CCCCCC",
            },
          ]),
        1000
      );
    });
  }

  async getById(id: string): Promise<{ data: string[] }> {
    return new Promise((resolve) => {
      setTimeout(() => {
        const data = ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Tags}-${id}`) ?? [];
        resolve({
          data,
        });
      }, 1000);
    });
  }

  async addById(text: string, id: string) {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          ServiceProvider.LocalStorage.setItem(`${LocalStorageKeys.Tags}-${id}`, [
            ...(ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Tags}-${id}`) ?? []),
            text
          ])
          resolve(text)
        },
        1000
      );
    })
  }

  async removeById(text: string, id: string) {
    return new Promise((resolve) => {
      setTimeout(
        () => {
          ServiceProvider.LocalStorage.setItem(`${LocalStorageKeys.Tags}-${id}`, 
            ServiceProvider.LocalStorage.getItem(`${LocalStorageKeys.Tags}-${id}`)
              .filter((item: string) => item !== text) ?? []
          )
          resolve(text)
        },
        1000
      );
    })
  }
}
