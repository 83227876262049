import { City } from "types";
import BaseService from "./baseService";

export default class CityService extends BaseService {
  constructor() {
    super("city");
  }

  async getAll(): Promise<City[]> {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve([
            {
              id: "1",
              name: "New York",
            },
            {
              id: "2",
              name: "Los Angeles",
            },
            {
              id: "3",
              name: "Washington",
            },
            {
              id: "4",
              name: "Tel Aviv-Yafo",
            },
          ]),
        1000
      );
    });
  }
}
