import BaseService from "./baseService";

export default class AuthService extends BaseService {
  constructor() {
    super("");
  }

  async login(emailAddress: string, password: string) {
    return super.post("", {
      query: `mutation($emailAddress: String!, $password: String!){
        login(emailAddress: $emailAddress, password: $password) {
          message
          token
          profile{
            accountId
            accountName
            address{
              city
              country
              latitude
              lineOne
              lineTwo
              longitude
              neighborhood
              postalCode
            }
            avatarUrl
            emailAddress
            firstName
            Id
            lastName
            phoneNumber
          }
        }
      }`,
      variables: {
        emailAddress,
        password,
      },
    });
  }

  async logout() {
    return new Promise((resolve) => {
      setTimeout(() => resolve(1), 1000);
    });
  }

  async getProfile() {
    return super.post("", {
      query: `query GetProfile {
                profile {
              accountId
              accountName
              address {
                city
                country
                latitude
                lineOne
                lineTwo
                longitude
                neighborhood
                postalCode
              }
              avatarUrl
              emailAddress
              firstName
              Id
              lastName
              phoneNumber
            }
      }`,
    });
  }
}
