import React, { useState, useEffect, useRef } from "react";
import { TextInput } from "flowbite-react";
import { IoClose, IoEllipsisVertical } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { Loader } from "../Loader/Loader";

interface TagInputProps {
  availableTags: string[];
  selectedTags: string[];
  onAdd: (tag: string) => void;
  onRemove: (tag: string) => void;
  isLoading?: boolean;
}

const TagInput: React.FC<TagInputProps> = ({
  availableTags,
  selectedTags,
  onAdd,
  onRemove,
  isLoading = false,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setShowPopover(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    if (value) {
      const selectedTagNames = selectedTags.map((tag) =>
        tag.toLowerCase()
      );
      const filtered = availableTags.filter(
        (tag) =>
          tag.toLowerCase().includes(value.toLowerCase()) &&
          !selectedTagNames.includes(tag.toLowerCase())
      );
      setFilteredTags(filtered);
      setShowDropdown(filtered.length > 0);
    } else {
      setShowDropdown(false);
    }
  };

  const handleTagSelect = (tag: string) => {
    if (!selectedTags.find((t) => t === tag)) {
      onAdd(tag);
    }
    setInputValue("");
    setShowDropdown(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && inputValue) {
      const existingTag = availableTags.find(
        (t) => t.toLowerCase() === inputValue.toLowerCase()
      ) || inputValue;
      if (!selectedTags.find((t) => t === existingTag)) {
        onAdd(existingTag);
      }
      setInputValue("");
      setShowDropdown(false);
    }
  };

  const handlePopoverToggle = () => {
    setShowPopover((prev) => !prev);
  };

  const visibleTags = selectedTags.slice(0, 4);
  const additionalTags = selectedTags.slice(4);

  return (
    <div className="flex gap-2 h-[2.875rem]">
      <div className="flex relative" ref={dropdownRef}>
        <TextInput
          id="tags"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={t("components.TagInput.placeholder")}
          autoComplete="off"
        />
        {showDropdown && (
          <ul className="absolute z-10 mt-[2.875rem] w-full bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-auto">
            {filteredTags.map((tag, index) => (
              <li
                key={index}
                onClick={() => handleTagSelect(tag)}
                className="px-3 py-2 cursor-pointer hover:bg-gray-100"
              >
                {tag}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="flex gap-1">
        {visibleTags.map((tag) => (
          <div
            key={tag}
            className="rounded-lg border border-highlighted flex gap-2 items-center px-3"
          >
            <IoClose
              className="h-4 w-4 cursor-pointer"
              onClick={() => onRemove(tag)}
            />
            <span>{tag}</span>
          </div>
        ))}
        {additionalTags.length > 0 && (
          <div
            className="relative flex items-center cursor-pointer"
            ref={popoverRef}
            onClick={handlePopoverToggle}
          >
            <IoEllipsisVertical className="h-4 w-4" />
            {showPopover && (
              <div className="absolute z-20 top-full right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-auto">
                {additionalTags.map((tag) => (
                  <div
                    key={tag}
                    className="px-3 py-2 cursor-pointer hover:bg-gray-100 flex items-center gap-2"
                    onClick={() => onRemove(tag)}
                  >
                    <IoClose className="h-4 w-4" />
                    {tag}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        {isLoading && (
          <div className='self-center ml-2'>
            <Loader size='m' />
          </div>
        )}
      </div>
    </div>
  );
};

export default TagInput;
