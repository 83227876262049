const CustomWeekViewHeader = ({ date }: any) => {
  
  const formatDateToString = () => {
    const days = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    
    const dayName = days[new Date(date).getDay()];
    const dayNumber = new Date(date).getDate();
    
    return `${dayName} ${dayNumber}`;
  }

  return (
    <p className='font-normal'>{formatDateToString()}</p>
  )
}

export default CustomWeekViewHeader;