import clsx from "clsx";
import { forwardRef } from "react";
import { Iconly } from "react-iconly";

interface IProps {
  label: string;
  icon?: React.ReactNode;
  isSelected?: boolean;
  showChevron?: boolean;
  onClick?: (event: any) => void;
}

const TypeButton = forwardRef<HTMLButtonElement, IProps>(
  ({ label, icon, isSelected, onClick, showChevron }, ref) => {
    return (
      <button
        ref={ref}
        className={clsx(
          `w-full h-20 rounded-lg border border-highlighted font-medium relative flex gap-2 items-center justify-center hover:text-highlighted`,
          {
            "text-highlighted": isSelected,
            "text-primary": !isSelected,
          }
        )}
        onClick={onClick}
      >
        {icon}
        {label}
        {showChevron && <Iconly name="ChevronDown" />}
      </button>
    );
  }
);

export default TypeButton;
