import { NavigateAction, View } from "react-big-calendar";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from 'flowbite-react';

interface ICustomToolbar {
  label: string,
  onView: (view: View) => void,
  onNavigate: (navigate: NavigateAction) => void,
}

const CustomToolbar = ({ label, onNavigate, onView }: ICustomToolbar) => {
  return (
    <div className='flex justify-between mb-8'>
      <div className='flex gap-6 items-center'>
        <p className='text-large'>{label}</p>
        <IoIosArrowBack className='cursor-pointer' onClick={() => onNavigate("PREV")}/>
        <IoIosArrowForward className='cursor-pointer' onClick={() => onNavigate("NEXT")}/>
      </div>
      <div className='flex gap-2'>
        <Button color="ghost" onClick={() => onView('day')}>Today</Button>
        <Button color="ghost" onClick={() => onView('week')}>Week</Button>
        <Button color="ghost" onClick={() => onView('month')}>Month</Button>
        <Button color="cta">Book event</Button>
      </div>
    </div>
  )
}

export default CustomToolbar;