import clsx from "clsx";
import React, { useState } from "react";

interface TabsProps {
  children: React.ReactNode;
  glowOnActive?: boolean;
  className?: string;
  containerClassName?: string;
  tabButtonsContainerClassName?: string;
  tabClassName?: string;
  activeTab: number;
  setActiveTab(index: number): void;
}

type TabsChild = React.ReactComponentElement<React.FC> & {
  props: {
    label: string;
    children: React.ReactNode;
  };
};

export function useTabs() {
  const [activeTab, setActiveTab] = useState<number>(0);

  return {
    activeTab,
    setActiveTab,
  };
}

const Tabs = ({
  children,
  glowOnActive = true,
  className = "",
  containerClassName,
  tabButtonsContainerClassName,
  tabClassName,
  activeTab,
  setActiveTab,
}: TabsProps) => {
  const customCta = React.Children.toArray(children)
    .filter((child) => (child as TabsChild).type === CustomCta)
    .map((child) => child as TabsChild);

  const tabComponents = React.Children.toArray(children)
    .filter((child) => (child as TabsChild).type === Tab)
    .map((child) => child as TabsChild);

  const tabLabels: Pick<TabProps, "label" | "labelCustomRender">[] =
    tabComponents.map((child) => {
      const props = (child as React.ReactElement).props;

      return { label: props.label, labelCustomRender: props.labelCustomRender };
    });

  const tabClassNames: string[] = tabComponents.map(
    (child) => (child as React.ReactElement).props.className
  );

  return (
    <div className={clsx("relative", containerClassName)}>
      <header
        className={clsx(
          `border-b-1 border-base-900 flex items-center justify-between border-0 pt-4`,
          className
        )}
      >
        <div className={clsx("flex px-2", tabButtonsContainerClassName)}>
          {tabLabels.map(({ label: tabLabel, labelCustomRender }, index) => (
            <button
              className={clsx(
                "relative py-2 px-6 text-sm border rounded ",
                {
                  "bg-highlighted text-white hover:text-white hover:border-transparent":
                    activeTab === index,
                  "hover:text-highlighted hover:border-highlighted":
                    activeTab !== index,
                },
                tabClassNames[index]
              )}
              key={tabLabel}
              onClick={() => setActiveTab(index)}
            >
              {labelCustomRender ? labelCustomRender(tabLabel) : tabLabel}
            </button>
          ))}
        </div>
        {customCta}
      </header>
      <div className={clsx("pt-4", tabClassName)}>
        {tabComponents.filter((_tabComponent, i) => i === activeTab)}
      </div>
    </div>
  );
};

type TabProps = {
  children: React.ReactNode;
  label?: string;
  className?: string;
  labelCustomRender?: (label?: string) => React.ReactNode;
};

export const Tab = ({ children, label, className = "" }: TabProps) => (
  <div key={label} className={clsx("py-2", className)}>
    {children}
  </div>
);

export const CustomCta = ({ children, className }: TabProps) => {
  return <div className={className}>{children}</div>;
};

export default Tabs;
