const modifyTimeString = (timeString: string) => {
  return `${timeString.split(' ')[0].split(':')[0]} ${timeString.split(' ')[1]}`
}

const CustomTimeslotWrapper = ({ children }: any) => {
  console.log(children.props.children?.props?.children || null)
  return (
    <div className='relative w-12 h-full'>
      {children.props.children?.props?.children ? (
        <p className='absolute h-full -top-3 font-normal'>{modifyTimeString(children.props.children.props.children)}</p>
      ) : null}
    </div>
  )
}

export default CustomTimeslotWrapper;