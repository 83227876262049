import React, { useState } from "react";

import { INote } from "types";
import dayjs from "dayjs";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { FcCancel, FcCheckmark } from "react-icons/fc";
import { TextInput } from "flowbite-react";

interface IProps {
  note: INote;
  handleDeleteNote: (noteId: string) => void;
  handleUpdateNote: (noteId: string, newText: string) => void;
}

const DATE_TIME_FORMAT = "MM/DD/YYYY hh:mm";

const Note: React.FC<IProps> = ({ note, handleDeleteNote, handleUpdateNote }) => {
  const [isEditActive, setEditActive] = useState(false);
  const [noteText, setNoteText] = useState(note.note);

  const handleSaveEditedNote = () => {
    handleUpdateNote(note.id, noteText);
    setEditActive(false);
  }

  const handleCancelEditing = () => {
    setEditActive(false);
  }

  return (
    <div
      key={note.note}
      className="shadow-md rounded-lg p-4 border-t-[1px]"
    >
      <div className="flex justify-between items-center mb-2">
        <span className="text-sm text-gray-500">
          {dayjs(note.createdAt).format(DATE_TIME_FORMAT)}
        </span>
        <div className='flex gap-2'>
          {isEditActive ? (
            <>
              <FcCheckmark
                className="w-5 h-5 ml-auto cursor-pointer"
                onClick={handleSaveEditedNote}
              />
              <FcCancel
                className="w-5 h-5 ml-auto cursor-pointer"
                onClick={handleCancelEditing}
              />
            </>
          ) : (
            <>
              <CiEdit
                className="w-5 h-5 ml-auto cursor-pointer"
                onClick={() => setEditActive(true)}
              />
              <AiOutlineDelete
                className="w-5 h-5 cursor-pointer"
                onClick={() => handleDeleteNote(note.id)}
              />
            </>
          )}
        </div>
      </div>
      {isEditActive ? (
        <TextInput value={noteText} onChange={(e) => setNoteText(e.target.value)} />
      ) : (
        <p className="text-secondary font-normal">{note.note}</p>
      )
      }
    </div>
  );
};

export default Note;
